<!--
 * @Description:
 * @Author: shaojing
 * @Date: 2022-06-12 14:23:31
 * @LastEditors: shaojing
 * @LastEditTime: 2022-06-12 15:02:42
-->
<template>
  <div style="display: flex">
    <button @click="zh_uploadFile" class="upload-btn" style="margin-top: 7px">上传文件</button>
    <div class="file-name" style="margin-left: 10px">
      {{ oldFileName }}
    </div>
    <input
      type="file"
      name="file"
      ref="evfile"
      style="display: none;"
      id="uploadBtn"
      accept="application/pdf"
      size="10"
      @change="upfileClick"
    />
  </div>
</template>

<script>
import { domainName } from "@/config/index";
import axios from "axios";
import { message } from "ant-design-vue";
export default {
  //选择上传文件
  props: {
    oldFileName: {
      type: String,
      default: "",
    },
  },
  methods: {
    zh_uploadFile() {
      this.$refs.evfile.click();
    },
    upfileClick(e) {
      const e_file = e.target;
      const file = e_file.files[0];
      let accept_s = (e_file.getAttribute("accept") || "*")
        .replace(/\s*/g, "")
        .split(","); // 获取input标签允许上传的文件类型
      let size = parseFloat(e_file.getAttribute("size") || 10);
      let maxSize = parseFloat((size * 1024 * 1024).toFixed(2));
      let [fileType, fileSuffix] = file.type.split("/");
      let isVerifiedStatus = 0;
      let isVerifiedMsg = {
        0: "未验证",
        1: "验证通过",
        2: `上传文件大小不能超过${size}MB`,
        3: "请上传PDF格式文件",
      };
      if (file.size <= maxSize) {
        for (let i = 0, accept; (accept = accept_s[i++]); ) {
          let [acceptType, acceptSuffix = "*"] = accept.split("/");
          if (accept == "*") {
            isVerifiedStatus = 1;
            break;
          } else if (fileType == acceptType) {
            if (acceptSuffix == "*" || fileSuffix == acceptSuffix) {
              isVerifiedStatus = 1;
              break;
            } else {
              isVerifiedStatus = 3;
            }
          } else {
            isVerifiedStatus = 3;
          }
        }
      } else {
        isVerifiedStatus = 2;
      }
      if (isVerifiedStatus != 1) {
        message.error(isVerifiedMsg[isVerifiedStatus]);
      } else {
        let param = new FormData();
        param.append("uploadFile", file);
        this.fileUploadCusDocs(param, this); // 调接口
      }
    },
    fileUploadCusDocs(data) {
      axios
        .post(`${domainName}/admin/files/upload/images`, data)
        .then((res) => {
          if (res.data.code === 200) {
            this.$emit("uploadOk", res.data.data);
            this.oldFileName = res.data.data.oldFileName;
            // } else {
            //   message.info(res.message);
          }
        });
    },
  },
};
</script>

<style>
.upload-btn {
  border: 1px solid #5084fe;
  padding: 5px 23px;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
  background: #fff;
  color: #333;
}
</style>
