// 课程管理相关接口
import request from '@/request/request'

// 课程列表接口
export const CourseInfotApi = (params) => request.post(`/admin/course/info/pageCourseInfo`, params);
// 上架课程 - java 废弃
export const CourseInfoOnLinetApi = (params) => request.get(`/admin/course/info/onLine`, { params });
// 下架课程 - java 废弃
export const CourseInfoOutApi = (params) => request.get(`/admin/course/info/out`, { params });
// 删除课程 接口
export const CourseInfoDeleteApi = (params) => request.get(`/admin/course/info/delete`, { params });
// 获取课程详情接口
export const CourseGetCourseInfoApi = (params) => request.get(`/admin/course/info/getCourseInfo`, { params });
// 获取老师列表（下拉框使用）
export const CourseTeacherSelectApi = () => request.post(`/admin/course/teacher/select/showTeacher`);
// 根据类型筛选标签列表(供下拉框使用) 标签类型 [1：课程 2：老师]
export const GetAllLabelsByTypeApi = (params) => request.get(`/admin/course/label/select/getAllLabelsByType`, { params });
// 新增课程
export const CourseInfoSaveCourseApi = (params) => request.post(`/admin/course/info/saveCourse`, params);
// 修改课程
export const CourseInfoUpdCourseApi = (params) => request.post(`/admin/course/info/updCourse`, params);
// 课程分享
export const CourseShareCourseLinkApi = (params) => request.get(`/admin/course/info/shareCourseLink`, { params });
//  排序课程
export const CourseInfosortOperationApi = (params) => request.post(`/admin/course/info/sortOperation`, params);





// 章节想关   查询对应目录下的课节
// export const GetCatalogueTreeResultApi = (params) => request.get(`/admin/course/catalogue/getCatalogueTreeResult`, { params });
export const GetCatalogueTreeResultApi = (params) => request.post(`/adminv2/catalogue/list`, params );
// 章节  新增目录
// export const SaveCatalogueApi = (params) => request.post(`/admin/course/catalogue/saveCatalogue`, params);
export const SaveCatalogueApi = (params) => request.post(`/adminv2/catalogue/add`, params);
// 章节  修改目录
// export const UpdCatalogueApi = (params) => request.post(`/admin/course/catalogue/updCatalogue`, params);
export const UpdCatalogueApi = (params) => request.post(`/adminv2/catalogue/update`, params);
// 章节  删除目录
// export const CatalogueDeleteApi = (params) => request.get(`/admin/course/catalogue/delete`, { params });
export const CatalogueDeleteApi = (params) => request.post(`/adminv2/catalogue/delete`, params );



// 课节获取老师列表（下拉框使用）
export const ShowTeacherApi = () => request.post(`/admin/course/teacher/select/showTeacher`);
// 课节  根据课程编号查询课节列表（分页）
export const GetCourseLessonsResultApi = (params) => request.post(`/admin/course/lessons/getCourseLessonsResult`, params);
// 上架课节
export const LessonsOnLineOutApi = (params) => request.get(`/admin/course/lessons/onLine`, { params });
// 下架课节
export const LessonsOutApi = (params) => request.get(`/admin/course/lessons/out`, { params });
// 复制课节链接
export const CopyCourseLessonsLinkApi = (params) => request.get(`/admin/course/lessons/copyCourseLessonsLink`, { params });
// 显示课节
export const LessonsShowApi = (params) => request.get(`/admin/course/lessons/show`, { params });
// 隐藏课节
export const LessonsHideApi = (params) => request.get(`/admin/course/lessons/hide`, { params });
// 获取课节详情
export const GetCourseLessonsDesResultApi = (params) => request.get(`/admin/course/lessons/getCourseLessonsDesResult`, { params });
// 根据课程编号查询章节列表（下拉框使用）
export const ShowCatalogueByCourseNoApi = (params) => request.get(`/admin/course/catalogue/select/showCatalogueByCourseNo`, { params });
// 新增课节
export const SaveLessonsApi = (params) => request.post(`/admin/course/lessons/saveLessons`, params);
// 修改课节
export const UpdLessonsApi = (params) => request.post(`/admin/course/lessons/updLessons`, params);
// 删除课节
export const LessonsDeleteApi = (params) => request.get(`/admin/course/lessons/delete`, { params });
// 排序课节
export const CatalogueSortOperationApi = (params) => request.post(`/admin/course/catalogue/sortOperation`, params);
// 获取主讲老师编号
export const GetMasterTeacherNoApi = (params) => request.get(`/admin/course/info/getMasterTeacherNo`, { params });

// 获取课程二级分类
export const GetCourseCategoryApi = () => request.post(`/adminv2/getCategoryList`);
// 取消发布分类
export const UpdateCateStatusApi =(params) =>request.post(`/adminv2/updateCateStatus`,params);
// 获取一级分类
export const GetCategoryApi = () => request.post(`/adminv2/getCateList`);
// 保存分类
export const SaveCategoryApi = (params) =>request.post(`/adminv2/saveCategory`,params)
export const UpdCategoryApi = (params) =>request.post(`/adminv2/saveCategory`,params)
//获取分类详情
export const GetCategoryDetailApi = (params) => request.post(`/adminv2/getCateInfoDetail`, params)
// 添加课程新版信息
export const SaveCourseInfoNew = (params) => request.post(`/adminv2/saveCourseInfoNew`, params);
// 获取课程新版本信息
export const GetCourseInfoNew = (params) => request.post(`/adminv2/getCourseInfoNew`, params);
//获取课程状态
export const GetCourseAppShowApi = (params) => request.post(`/adminv2/getCourseAppShow `, params);
//设置课程状态
export const SetCourseAppShowApi = (params) => request.post(`/adminv2/setCourseAppShow `, params);
export const CourseGetCourseInfoApiV2 = (params) => request.post(`/adminv2/getCourseAddInfo`,params)
export const GetCourseLessonsDesResultApiV2 = (params) => request.post(`/adminv2/getLessonsInfo`,params)
export const SaveLessonsApiv2 = (params) => request.post(`/adminv2/AddLessonsInfo`, params);
export const UpdLessonsApiv2 = (params) => request.post(`/adminv2/AddLessonsInfo`, params);
export const CourseInfoSaveCourseApiv2 = (params) => request.post(`/adminv2/addCourseInfo`, params);
export const CourseInfoUpdCourseApiv2 = (params) => request.post(`/adminv2/addCourseInfo`, params);


// 关系图谱接口

//添加人物
export const AddOneAtlasInfo = (params) => request.post(`/adminv2/addOneAtlasInfo`, params);

//获取列表
export const GetAtlasList = () => request.post(`/adminv2/getAtlasList`);

//获取人物信息-详情
export const GetOneAtlasInfo = (params) => request.post(`/adminv2/getOneAtlasInfo`, params);

//获取家族信息
export const GetFamilyList = () => request.post(`/adminv2/getFamilyList`);

//获取年代列表
export const GetYearList = () => request.post(`/adminv2/getYearList`);

//获取同家族人物
export const GetFamilyPeople = (params) => request.post(`/adminv2/getFamilyPeople`, params);

//获取身份列表
export const GetIdentityList = () => request.post(`/adminv2/getIdentityList`);

//删除人物
export const DelPeople = (params) => request.post(`/adminv2/delPeople`, params);

//获取章节列表
export const GetAtlasLessonsList = (params) => request.post(`/adminv2/getAtlasLessonsList`, params);

//添加课程海报
export const AddCoursePlaybill = (params) => request.post(`adminv2/addCoursePlaybill`, params);

//获取课程海报列表
export const GetCoursePlaybill = (params) => request.post(`adminv2/getCoursePlaybill`, params);

// 添加商品-获取课程信息
export const getAddCourseInfoAPI = (params) => request.post('/adminv2/getCourseInfo', params);

// 课程上架
export const lessonsOnlineAdminV2Api = (params) => request.post('/adminv2/course/lessons/online', params);
// 课程下架
export const lessonsOutAdminV2Api = (params) => request.post('/adminv2/course/lessons/out', params);


// 课节分类接口
// 列表
export const categoryList = (params) => request.post('/adminv2/lessons/category/list', params);
// 新增
export const categoryAdd = (params) => request.post('/adminv2/lessons/category/add', params);
// 编辑
export const categoryUpdate = (params) => request.post('/adminv2/lessons/category/update', params);


// 学习计划
// 获取学习计划列表
export const studyPlanList = (params) => request.post('/adminv2/studyplant/stage/list', params);
// 开启/关闭学习计划
export const studyPlanSwitch = (params) => request.post('/adminv2/studyplant/stage/switch', params);
// 添加学习计划
export const addStudyPlan = (params) => request.post('/adminv2/studyplant/stage/add', params);
// 编辑学习计划
export const editStudyPlan = (params) => request.post('/adminv2/studyplant/stage/edit', params);
// 上架/下架
export const putawayStudyPlan = (params) => request.post('/adminv2/studyplant/stage/putaway', params);
// 标签列表
export const studyTagsList = (params) => request.post('/adminv2/studyplant/tags/list', params);
// 添加标签
export const addStudyTags = (params) => request.post('/adminv2/studyplant/tags/add', params);
// 修改标签
export const editStudyTags = (params) => request.post('/adminv2/studyplant/tags/edit', params);
// 上架/下架标签
export const putawayStudyTags = (params) => request.post('/adminv2/studyplant/tags/putaway', params);
// 获取标签内容-图片类型标签
export const studyTagsDetail = (params) => request.post('/adminv2/studyplant/tags/content/detail', params);
// 保存标签内容-图片类型标签
export const studyTagsContentSave = (params) => request.post('/adminv2/studyplant/tags/content/set', params);
// 标签内容-目录列表
export const studyCatalogueList = (params) => request.post('/adminv2/studyplant/tags/catalogue/list', params);
// 标签内容-添加目录
export const studyCatalogueSave = (params) => request.post('/adminv2/studyplant/tags/catalogue/add', params);
// 标签内容-编辑目录
export const studyCatalogueEdit = (params) => request.post('/adminv2/studyplant/tags/catalogue/edit', params);
// 标签内容-删除目录
export const studyTagCatalogueDel = (params) => request.post('/adminv2/studyplant/tags/catalogue/del', params);
// 标签内容-课节列表
export const studyTagsLessonList = (params) => request.post('/adminv2/studyplant/tags/lessons/list', params);
// 标签内容-添加课节
export const studyTagsLessonSave = (params) => request.post('/adminv2/studyplant/tags/lessons/add', params);
// 标签内容-编辑课节
export const studyTagsLessonEdit = (params) => request.post('/adminv2/studyplant/tags/lessons/edit', params);
// 标签内容-删除课节
export const studyTagsLessonDel = (params) => request.post('/adminv2/studyplant/tags/lessons/del', params);

// 课程详情列表
export const courseModuleList = (params) => request.post('/adminv2/course/module/list', params);
// 课程详情-详情
export const courseModuleDetail = (params) => request.post('/adminv2/course/module/detail', params);
// 课程详情-添加
export const addCourseModule = (params) => request.post('/adminv2/course/module/add', params);
// 课程详情-编辑
export const editCourseModule = (params) => request.post('/adminv2/course/module/edit', params);
// 课程详情-上架
export const updateCourseModuleStatus = (params) => request.post('/adminv2/course/module/status', params);
// 课程详情-展示用户列表
export const showUserPhone = (params) => request.post('/adminv2/course/module/phone/list', params);
// 课程详情-删除用户列表
export const delUserPhone = (params) => request.post('/adminv2/course/module/phone/delete', params);
// 课程详情-用户列表-添加
export const addUserPhone = (params) => request.post('/adminv2/course/module/phone/add', params);
// 课程详情-内容管理- 按钮/图片
export const getCourseModuleContent = (params) => request.post('/adminv2/course/module/content/get', params);
// 课程详情-内容管理- 按钮/图片
export const setCourseModuleContent = (params) => request.post('/adminv2/course/module/content/set', params);
// 课程详情-目录列表
export const courseModuleCatalogueList = (params) => request.post('/adminv2/course/module/catalogue/list', params);
// 课程详情-添加目录
export const addCourseModuleCatalogue = (params) => request.post('/adminv2/course/module/catalogue/add', params);
// 课程详情-编辑目录
export const editCourseModuleCatalogue = (params) => request.post('/adminv2/course/module/catalogue/edit', params);
// 课程详情-删除目录
export const delCourseModuleCatalogue = (params) => request.post('/adminv2/course/module/catalogue/delete', params);
// 课程详情-目录排序
export const sortCourseModuleCatalogue = (params) => request.post('/adminv2/course/module/catalogue/sort', params);
// 课程详情-课节列表
export const courseModuleLessonsList = (params) => request.post('/adminv2/course/module/lessons/list', params);
// 课程详情-添加课节
export const addCourseModuleLessons = (params) => request.post('/adminv2/course/module/lessons/add', params);
// 课程详情-编辑课节
export const editCourseModuleLessons = (params) => request.post('/adminv2/course/module/lessons/edit', params);
// 课程详情-删除课节
export const delCourseModuleLessons = (params) => request.post('/adminv2/course/module/lessons/delete', params);
// 课程详情-课节排序
export const sortCourseModuleLessons = (params) => request.post('/adminv2/course/module/lessons/sort', params);
// 课程详情-阶段列表
export const courseModuleStageList = (params) => request.post('/adminv2/course/module/stage/list', params);
// 课程详情-获取阶段描述
export const getCourseModuleStageDescribe = (params) => request.post('/adminv2/course/module/stage/descb/get', params);
// 课程详情-设置阶段描述
export const setCourseModuleStageDescribe = (params) => request.post('/adminv2/course/module/stage/descb/set', params);
// 课程详情-新建阶段
export const addCourseModuleStage = (params) => request.post('/adminv2/course/module/stage/add', params);
// 课程详情-阶段上架/下架
export const updateCourseModuleStageStatus = (params) => request.post('/adminv2/course/module/stage/status', params);
// 课程详情-编辑阶段
export const editCourseModuleStage = (params) => request.post('/adminv2/course/module/stage/edit', params);
