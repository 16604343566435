<template>
    <!-- 上传图片组件 -->
    <div>
        <div class="maxBox">
            <div class="imgClass">
                <template v-if="imgUrl">
                    <div class="box" @mouseenter="handleMouse" @mouseleave="indexChange = false" >
                        <img :src="imgUrl" alt="">
                        <div class="mark" v-show="indexChange" @click="circleClick">删除</div>
                    </div>
                </template>
                <div class="updClass" v-else>
                    <a-icon type="plus" />
                    <span>上传图片</span>
                    <input type="file" ref="upFile" accept="image/png,image/jpg,image/jpeg" @change="File()"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { domainName } from "@/config/index";
import axios from "axios";
import { message } from "ant-design-vue";
export default {
    props: {
        imageUrlNum: {
            type: Number,
            default: "",
        },
        imgUrl: {
            type: String,
            default: '',
        },
        avatarTextBottom: {
            type: String,
            default: "图片小于1M",
        },
        urlSize:{
            type: Number,
            default: 500,
        },
        sizeWh:{
            type: Number,
            default: 0,
        },
        itemIndex:{
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            isShow:false,
            indexChange:false,
            action: `${domainName}/admin/files/upload/images`,
        };
    },
    methods: {
        circleClick(){
            this.$emit("circlefns");
        },
        
        handleMouse(){
            this.indexChange = true
        },
        
        File() {
            let maxSize = this.urlSize
            let files = this.$refs.upFile.files[0];
            if(files.size > maxSize*1024){
                message.warning("图片大小不能超过500k");
                return false
            }
            var reader = new FileReader();
            reader.readAsDataURL(files); //调用自带方法进行转换
            reader.onload = (e) => {
                if(this.sizeWh == 1){
                    let width = 0
                    let height = 0
                    var image = new Image();
                    image.src = e.target.result;
                    image.onload = function() {
                        width = this.width
                        height = this.height
                    };
                    setTimeout(()=>{
                        console.log(width,height)
                        if(width > 650 || height > 1000){
                            message.warning("请上传小于等于650*1000图片！");
                            return false
                        }else{
                            let file = this.dataURLtoFile(e.target.result, files.name);
                            let formData = new window.FormData();
                            formData.append("uploadFile", file);
                            axios.post(`${domainName}/admin/files/upload/images`, formData).then((res) => {
                                if (res.data.code == 200) {
                                    this.imgUrl = res.data.data.url
                                    this.$emit("avatarfns", this.imgUrl,this.itemIndex);
                                }
                            });
                        }
                    },1000)
                }else{
                    let file = this.dataURLtoFile(e.target.result, files.name);
                    let formData = new window.FormData();
                    formData.append("uploadFile", file);
                    axios.post(`${domainName}/admin/files/upload/images`, formData).then((res) => {
                        if (res.data.code == 200) {
                            this.imgUrl = res.data.data.url
                            this.$emit("avatarfns", this.imgUrl,this.itemIndex);
                        }
                    });
                }
            };
        },
        
        // base 64 转成二进制文件流
        dataURLtoFile(dataurl, filename) {56
            var arr = dataurl.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type: mime});
        },
    },
    
    mounted() {
    }
};
</script>

<style lang="less" scoped>
.maxBox{
    display: flex;
    justify-content: flex-start;
    .imgClass{
        display: flex;
        flex-wrap: wrap;
        .box{
            overflow: hidden;
            width: 102px;
            height: 102px;
            background-color: #fafafa;
            border: 1px dashed #d9d9d9;
            border-radius: 4px;
            padding: 5px;
            margin-right: 10px;
            margin-bottom: 10px;
            position: relative;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
            .mark{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                background: rgba(0,0,0,.6);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 15px;
                flex-wrap: 500;
                color: #fff;
                text-decoration:underline
            }
        }
        .updClass{
            width: 102px;
            height: 102px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #fafafa;
            border: 1px dashed #d9d9d9;
            font-size: 12px;
            overflow: hidden;
            position: relative;
            input{
                width: 102px;
                height: 102px;
                position: absolute;
                cursor: pointer;
                outline: medium none;
                filter: alpha(opacity=0);
                -moz-opacity: 0;
                opacity: 0;
                z-index: 100;
            }
        }
    }
}
</style>
