<template>
  <!-- 上传音视频组件 -->
  <div class="upload">
    <a-upload
      name="uploadFile"
      list-type="picture-card"
      :action="action"
      :beforeUpload="beforeUpload"
      @change="handleChange"
      accept="audio/mpeg"
    >
      <div v-if="isDisabled" @mouseover="mouseover" @mouseleave="mouseLeave">
        <a-icon :type="!isDisabled ? 'loading' : 'plus'" v-if="!fileName" />
        <div class="ant-upload-text">
          {{ !fileName ? "上传" : fileName }}
        </div>
      </div>
    </a-upload>
    <div class="text">
      格式支持mp3, 为保证音频加载与播放的流畅性, 建议上传大小不超过100M
    </div>
    <audio
      v-show="false"
      @canplay="getDuration"
      @timeupdate="updateTime"
      ref="audio"
      :src="musicUrl"
    ></audio>
    <div class="tips" v-show="isShowTips">{{ fileName }}</div>
  </div>
</template>

<script>
import { message } from "ant-design-vue";
import { domainName } from "@/config/index";
export default {
  props: ["fileName"],
  data() {
    return {
      isDisabled: true,
      action: `${domainName}/admin/files/upload/audio`,
      musicUrl: "",
      isShowTips: false, // 提示标题是否显示
    };
  },
  methods: {
    handleChange(info) {
      if (info.fileList.length >= 1) {
        this.isDisabled = false;
        if (info.fileList[0].response) {
          let { code, data } = info.fileList[0].response;
          if (code === 200) {
            this.musicUrl = data.url;
            message.success("上传成功");
            this.$emit("audioFn", data);
          }
        }
      } else {
        this.isDisabled = true;
      }
    },
    beforeUpload(file) {
      console.log(file);
      this.$emit("audioLoadingFn", true);
      return (
        false,
        Promise.all([
          this.checkType(file, ["audio/mpeg"]), //"video/mp4", "video/ogg",
        ])
      );
    },
    // 文件类型
    checkType(file, typeList) {
      let that = this;
      return new Promise(function (resolve, reject) {
        if (!typeList.includes(file.type)) {
          that.$message.error("音频文件类型错误，请重新上传");
          that.$emit("audioLoadingFn", false);
          reject();
        } else {
          resolve();
        }
      });
    },
    getDuration() {
      // console.log(this.$refs.audio.duration); //此时可以获取到duration
      let duration = this.$refs.audio.duration;
      this.$emit("audioTime", duration);
    },
    updateTime(e) {
      // let currentTime = e.target.currentTime; //获取audio当前播放时间
      // console.log(currentTime);
      // this.$emit("audioTime", currentTime);
    },
    // 鼠标移入
    mouseover() {
      if (this.fileName) {
        this.isShowTips = true;
      }
    },
    // 鼠标移出
    mouseLeave() {
      this.isShowTips = false;
    },
  },
};
</script>
 
<style lang="less" scoped>
.ant-upload-picture-card-wrapper {
  width: auto;
}
.upload {
  display: flex;
  position: relative;
  .text {
    margin-left: 20px;
    font-weight: 600;
    font-size: 14px;
  }
}
.tips {
  position: absolute;
  top: -42px;
  left: 0;
}
</style>