<template>
  <!-- 添加/编辑课节页面 -->
  <div class="positionT0L0">
    <div v-show="$route.meta.isShow">
      <p class="title"><span></span> 基本信息</p>
      <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="{ span: 2 }" :wrapper-col="{ span: 8 }">
        <a-form-model-item label="课节类型" prop="lessonsType">
          <a-select show-search v-model="form.lessonsType" placeholder="点击选择课节类型" option-filter-prop="children" style="width: 200px" :getPopupContainer="(triggerNode) => triggerNode.parentNode || document.body" @change="lessonsTypeChange">
            <a-select-option :value="item.key" v-for="item in lessonsTypeArr" :key="item.labelNo">{{ item.value }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="课节名称" prop="name" class="por">
          <a-input v-model="form.name" :maxLength="32" placeholder="请输入课节名称">
            <div slot="addonAfter">{{ form.name.length }}/32</div>
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="课节介绍" prop="brief">
          <a-textarea placeholder="请输入课节介绍" v-model="form.brief" :maxLength="300" :rows="5"/>
          <span class="form_span">{{ form.brief.length }}/300</span>
        </a-form-model-item>
        <a-form-model-item label="主讲老师" prop="teacherNo">
          <a-select show-search v-model="form.teacherNo" placeholder="点击选择主讲老师" option-filter-prop="children" style="width: 200px" :getPopupContainer="(triggerNode) => triggerNode.parentNode || document.body" @change="teacherNoChange">
            <a-select-option :value="item.labelNo" v-for="item in teacherNoArr" :key="item.labelNo">{{ item.labelText }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="所属章节" prop="catalogueNo" v-if="catalogueFlag == 1">
          <a-select show-search v-model="form.catalogueNo" placeholder="点击选择所属章节" option-filter-prop="children" style="width: 200px" :disabled="catalogueFlag == '0'" :getPopupContainer="(triggerNode) => triggerNode.parentNode || document.body" @change="catalogueNoChange">
            <a-select-option :value="item.labelNo" v-for="item in catalogueNoArr" :key="item.labelNo">{{ item.labelText }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="所属章节" v-if="catalogueFlag == 0" required>
          <span>无章节</span>
        </a-form-model-item>
        <a-form-model-item label="所属分类">
          <a-select show-search v-model="form.lessonsCategories" mode="multiple" option-filter-prop="children" placeholder="点击选择分类" style="width: 200px" :getPopupContainer="(triggerNode) => triggerNode.parentNode || document.body">
            <a-select-option :value="item.id" v-for="item in classification" :key="item.id">{{ item.categoryName }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <p class="title"><span></span> 内容编辑</p>
        <a-form-model-item label="伴读文稿" :wrapper-col="{ span: 16 }" class="description">
          <Ueditor :content="form.pairedDes" id="pairedDes" @editorFn="pairedDeseditorFn"/>
        </a-form-model-item>
        <a-form-model-item label="文稿" :wrapper-col="{ span: 16 }" class="description">
          <!--<Ueditor :content="form.manuscript" id="manuscript" @editorFn="pairedDeseditorFnNew"/>-->
          <div class="wengao">
            <div v-for="(item,index) in manuscriptList" :key="index" style="display: flex;margin-bottom: 20px">
              <div style="flex: 1">
                <div>
                  <a-radio-group v-model="item.type" size="small" button-style="solid" @change="typeChange(index)" :key="index">
                    <a-radio-button :value="1">文本</a-radio-button>
                    <a-radio-button :value="2">图片</a-radio-button>
                  </a-radio-group>
                </div>
                <div v-if="item.type == 1">
                  <a-textarea placeholder="请输入内容" :rows="4" v-model="item.text" show-count :key="index"/>
                </div>
                <div v-else>
                  <UpdataImg @avatarfns="goodSentenceUrlFn" @circlefns="deldteImgFn(index)" :imageUrlNum="5" :itemIndex="index" :imgUrl="item.text" :key="index"></UpdataImg>
                </div>
              </div>
              <a-icon @click="minusClick(index)" v-if="index != 0" type="minus" style="font-size: 25px;margin-top: 65px;margin-left: 20px"/>
            </div>
            <p style="display: flex;justify-content: center">
              <a-icon type="plus" @click="plusClick" style="font-size: 25px;margin-top: 10px;margin-left: 20px"/>
            </p>
          </div>
        </a-form-model-item>
        <a-form-model-item label="伴读上传">
          <CropperPdf @uploadOk="uploadOk" :oldFileName="form.pdfOldFileName"></CropperPdf>
        </a-form-model-item>
        <a-form-model-item label="主件上传" :wrapper-col="{ span: 18 }" prop="oldFileName">
          <!-- 主件上传 -->
          <QiniuVideoAudio v-if="qiniuVideoAudio" :oldFileName="form.oldFileName" :type="form.lessonsType == 3 ? 1 : 2" id="videoUpdate" @videoFn="videoFn" @changeOldFileName="changeOldFileName"/>
          <div style="font-weight: 600;line-height: 20px">支持mp3、mp4格式；建议文件不超过500M。保存课节后理论上不建议更改，如需更改建议时长不能大于第一次的内容，否则会导致课节完播率丢失。</div>
          <span v-show="oldFileNameMp4Show" style="color:#f5222d">请上传主件</span>
        </a-form-model-item>
        <a-form-model-item label="附件上传" :wrapper-col="{ span: 18 }">
          <!-- 附件上传 -->
          <QiniuVideoAudio v-if="qiniuVideoAudio" :oldFileName="form.appendixName" :type="form.lessonsType == 3 ? 2 : 1" id="videoUpdate" @videoFn="audioFn" @changeOldFileName="changeOldFileNameMp3"/>
          <div style="font-weight: 600;line-height: 20px">支持mp3、mp4格式；建议文件不超过500M。保存课节后理论上不建议更改，如需更改建议时长不能大于第一次的内容，否则会导致课节完播率丢失。</div>
        </a-form-model-item>
        <a-form-model-item label="打点类型" prop="pointType">
          <a-popover title="打点类型" style="margin-right: 10px">
            <template slot="content">
              <p>打点类型是为了统计用户学习本节视频的完播率以及播放次数</p>
              <p>普通型是打点间隔为10s,适用于对统计学习情况有要求,但是无序很精准的视频,如概论课</p>
              <p>精细型是打点间隔为5s,适用于需要精确统计用户学习情况的视频, 如重点课</p>
              <p>粗略型是打点间隔为15s,适用于只需要简单统计用户完播率的视频, 如导论课</p>
            </template>
            <a-icon type="question-circle"/>
          </a-popover>
          <a-radio-group :disabled="$route.params.id !== '0'" name="radioGroup" :value="form.pointType" @change="pointTypeChange">
            <a-radio :value="1"> 普通型</a-radio>
            <a-radio :value="2"> 精细型</a-radio>
            <a-radio :value="3"> 粗略型</a-radio>
          </a-radio-group>
          <div style="font-size: 14px; color: #ccc">打点类型只能选择一次,保存课节后不能修改,请慎重选择</div>
        </a-form-model-item>
        <a-form-model-item :label="label.coverUrlLabel" prop="coverUrl" v-if="form.lessonsType == 3">
          <CropperUpload :imageUrl="form.coverUrl" :options="coverOptionsAudio" @avatarfn="coverUrlFn" avatarTextTop="建议尺寸370px*426px,JPG.PNG.JPEG格式," avatarTextBottom="图片小于1M"/>
        </a-form-model-item>
        <a-form-model-item :label="label.descriptionLabel" prop="description" :wrapper-col="{ span: 16 }" class="description">
          <Ueditor :content="form.description" @editorFn="editorFn"/>
        </a-form-model-item>
        <p class="title"><span></span> 学习设置</p>
        <a-form-model-item label="允许试学" prop="studyFlag">
          <a-radio-group name="radioGroup" :value="form.studyFlag" @change="studyFlagChange">
            <a-radio :value="1"> 是</a-radio>
            <a-radio :value="0"> 否</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <p class="title"><span></span> 上架设置</p>
        <a-form-model-item ref="putawayFlag	" label="上架设置" prop="putawayFlag">
          <a-radio-group :value="form.putawayFlag" @change="putawayFlagChange">
            <a-radio :style="radioStyle" :value="2">立即上架</a-radio>
            <a-radio :style="radioStyle11" :value="1"> 定时上架</a-radio>
            <template v-if="form.putawayFlag == 1">
              <template>
                <a-date-picker :locale="locale" :value="form.putawayTime ? form.putawayTime : null" :format="dateFormat" show-time placeholder="请选择上架时间" @change="putawayTimeChange" @ok="putawayTimeOk"/>
              </template>
            </template>
            <a-radio :style="radioStyle" :value="3"> 暂不上架</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="是否隐藏" prop="hideFlag">
          <a-radio-group name="radioGroup" :value="form.hideFlag" @change="hideFlagChange">
            <a-radio :value="1"> 是</a-radio>
            <a-radio :value="0"> 否</a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
      <FooterToolBar :collapsed="sideCollapsed">
        <a-button class="margin_right60" @click="() => $router.go(-1)">返回</a-button>
        <a-button type="primary" @click="keepClick" :loading="keepLoading" :disabled="keepLoading">保存</a-button>
      </FooterToolBar>
    </div>
  </div>
</template>

<script>
import {baseMixin} from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import {message} from "ant-design-vue";
import CropperPdf from '@/components/file/CropperPdf.vue'
import {
  CourseTeacherSelectApi,
  ShowCatalogueByCourseNoApi,
  GetMasterTeacherNoApi,
  GetCourseLessonsDesResultApiV2,
  SaveLessonsApiv2,
  UpdLessonsApiv2, categoryList,
} from "@/request/api/coursManage";
import {DictionaryApi} from "@/request/api/login";
import Audio from "@/components/file/Audio.vue";
import QiniuVideoAudio from "@/components/file/QiniuVideoAudio.vue";
import CropperUpload from "@/components/file/CropperUpload";
import Ueditor from "@/components/ueditor/ueditor";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import moment from "moment";
import {format} from "@/utils/tools";
import UpdataImg from "@/components/file/aUpdataImg";

// 课节类型
const lessonsTypes = {
  audio: {
    value: 3,
    text: '音频'
  },
  video: {
    value: 1,
    text: '视频'
  }
};

// 章节类型
const catalogueTypes = {
  audio: {
    value: 1,
    text: '音频',
    mediaType: ['.mp3']
  },
  video: {
    value: 2,
    text: '视频',
    mediaType: ['.mp4']
  }
};

// 章节类型和课节类型的映射关系
const catalogueTypeWithLessonsTypeMap = {
  [catalogueTypes.audio.value]: lessonsTypes.audio,
  [catalogueTypes.video.value]: lessonsTypes.video
};
const lessonsTypeToCatalogueTypeMap = {
  [lessonsTypes.audio.value]: catalogueTypes.audio,
  [lessonsTypes.video.value]: catalogueTypes.video,
};

export default {
  mixins: [baseMixin],
  created() {
    let id = this.$route.params.id;
    this.form.courseNo = localStorage.getItem("courseNo");
    this.catalogueFlag = localStorage.getItem("catalogueFlag");
    if (id !== "0") {
      this.GetCourseLessonsDesResultFn();
    } else if (id == "0") {
      GetMasterTeacherNoApi({courseNo: this.form.courseNo}).then(
        ({code, data}) => {
          if (code == 200) this.form.teacherNo = data.masterTeacherNo; // 获取主讲老师编号
        }
      );
    }
    // 接口返回的课节类型只有音频课，这里先前端写死
    // this.DictionaryFn();
    this.lessonsTypeArr = [
      {key: lessonsTypes.audio.value, value: lessonsTypes.audio.text},
      {key: lessonsTypes.video.value, value: lessonsTypes.video.text}
    ];
    this.teacherSelectFn();
    if (this.catalogueFlag == "1") {
      this.ShowCatalogueByCourseNoFn(this.form.courseNo);
    }
    // 优先根据章节类型展示，无章节时根据课程类型展示
    this.changeUploadType()
    this.ClassClassification()
  },
  components: {
    Audio,
    CropperUpload,
    Ueditor,
    FooterToolBar,
    QiniuVideoAudio,
    CropperPdf,
    UpdataImg
  },
  data() {
    return {
      classification:[],
      qiniuVideoAudio:true,
      manuscriptList:[
        {
          text:'',
          type:1,
        }
      ],
      oldFileNameMp4Show:false,
      oldFileNameMp3Show:false,
      label: {
        coverUrlLabel: "音频封面",
        descriptionLabel: "音频详情",
      },
      courseType: 1,
      locale,
      dateFormat: "YYYY-MM-DD HH:mm:ss",
      defaultTime: null, // 编辑页面时间默认值
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      radioStyle11: {
        height: "30px",
        lineHeight: "30px",
      },
      catalogueFlag: 0, // 是否分章节
      lessonsTypeArr: [], // 课节类型数组
      teacherNoArr: [{labelNo: "", labelText: "张三"}], // 主讲老师数组
      catalogueNoArr: [{labelNo: "", labelText: "无章节"}], // 章节数组
      keepLoading: false, // 保存的按钮是否加载
      isShowPointType: false, // 打点类型的提示文字是否显示
      form: {
        courseNo: "",
        lessonsType: 1,
        name: "",
        brief: "",
        teacherNo: undefined,
        catalogueNo: undefined,
        fileCosKey: "",
        oldFileName: "",
        videoTime: 0,
        fileUrl: "",
        pointType: 1,
        coverUrl: "",
        coverCosKey: "",
        description: "",
        studyFlag: 0,
        putawayFlag: 2,
        putawayTime: null,
        hideFlag: 0,
        fileSize: null,
        pairedDes: '',
        pdfUrl: '',
        pdfOldFileName: '',
        appendix:[],
        // appendixName:'',
        // appendixCosKey:'',
        // appendixUrl:'',
        // appendixTime:0,
        // fileSizeMp3:null,
        lessonsCategories:[],
        manuscript:[],
      },
      rules: {
        lessonsType: [
          {required: true, message: "请选择课节类型", trigger: "change"},
        ],
        oldFileName: [
          {required: true, message: "",}
        ],
        oldFileNameMp3: [
          {required: true, message: "",}
        ],
        name: [
          {required: true, message: "输入的内容不能为空", trigger: "blur",},
          {max: 32, message: "长度最大为32个", trigger: "blur",},
        ],
        teacherNo: [
          {required: true, message: "请选择主讲老师", trigger: "change"},
        ],
        catalogueNo: [
          {required: true, message: "请选择所属章节", trigger: "change"},
        ],
        pointType: [{required: true, message: "请选择打点类型"}],
        coverUrl: [{required: true, message: "请上传音频封面"}],
        studyFlag: [{required: true}],
        putawayFlag: [{required: true}],
        hideFlag: [{required: true}],
      },
      coverOptionsAudio: {
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: 370, //默认生成截图框宽度
        autoCropHeight: 426, //默认生成截图框高度
        fixedBox: true, //是否固定截图框大小 不允许改变
        previewsCircle: false, //预览图是否是原圆形
        title: "修改图片",
      }
    };
  },
  computed: {
    // 当前选中的章节类型
    currCatalogueType() {
      const catalogueNo = this.form.catalogueNo;
      const catalogueNoArr = this.catalogueNoArr;
      const catalogueType = this.getCurrCatalogueType(catalogueNo);
      return catalogueType;
    }
  },
  methods: {
    moment,
    
    typeChange(index){
      this.manuscriptList[index].text = ''
    },
    
    plusClick(){
      let obj = {
        text:'',
        type:1,
      }
      this.manuscriptList.push(obj)
    },
    
    minusClick(index,item) {
      this.manuscriptList.splice(index,1)
    },
    
    goodSentenceUrlFn(data,index) {
      this.manuscriptList[index].text = data
    },
    
    deldteImgFn(index){
      this.manuscriptList[index].text = ''
    },
    
    uploadOk(value) {
      this.form.pdfUrl = value.url
      this.form.pdfOldFileName = value.oldFileName
    },
    // 切换上传类型
    changeUploadType(cataloguType) {
      // this.courseType = cataloguType || localStorage.getItem("courseType");
      if (this.form.lessonsType == 3) {
        // this.lessonsTypeArr = [{ key: 3, value: "音频" }];
        this.form.lessonsType = 3;
        this.label.coverUrlLabel = "音频封面";
        this.label.descriptionLabel = "音频详情";
        this.rules.coverUrl = [{required: true, message: "请上传音频封面"}];
      } else if (this.form.lessonsType == 1) {
        // this.lessonsTypeArr = [{ key: 1, value: "视频" }];
        this.form.lessonsType = 1;
        this.label.coverUrlLabel = "视频封面";
        this.label.descriptionLabel = "视频详情";
        this.rules.coverUrl = [{required: true, message: "请上传视频封面"}];
      }
    },
    // 课节类型下拉框改变的值
    lessonsTypeChange(value) {
      this.qiniuVideoAudio = false
      setTimeout(()=>{
        this.qiniuVideoAudio = true
      },200)
      this.form.lessonsType = value;
      this.changeUploadType(this.currCatalogueType);
    },
    // 主讲老师改变的回调
    teacherNoChange(value) {
      this.form.teacherNo = value;
    },
    // 检测章节类型是否支持音/视频
    checkCatalogueType(value) {
      const needLessonsType = catalogueTypeWithLessonsTypeMap[value];
      if (needLessonsType.value === this.form.lessonsType) {
        return true;
      } else {
        const errMsg = `所选章节仅支持${needLessonsType.text}类型`
        message.error(errMsg);
        return false;
      }
    },
    // 根据CatalogueNo获取当前所选章节的数据
    findByCatalogueNo(value) {
      const curr = this.catalogueNoArr.filter(item => item.labelNo === value);
      return curr.length ? curr[0] : {};
    },
    // 获取所选章节的类型
    getCurrCatalogueType(value) {
      const data = this.findByCatalogueNo(value);
      return data.labelType;
    },
    // 根据课节类型获取章节类型
    getCatalogueTypeByLessonType(lessonsType) {
      let cataType;
      if (lessonsType) {
        cataType = lessonsTypeToCatalogueTypeMap[lessonsType].value;
      }
      return cataType;
    },
    // 所属章节改变的回调
    catalogueNoChange(value) {
      this.form.catalogueNo = value;
      // const currCatalogueType = this.currCatalogueType;
      // const isValid = this.checkCatalogueType(currCatalogueType);
      // if (isValid) {
      //     this.form.catalogueNo = value;
      // } else {
      //     this.form.catalogueNo = '';
      // }
    },
    // 改变打点类型
    pointTypeChange(e) {
      this.form.pointType = e.target.value;
    },
    // 封面封装函数
    coverUrlFn(data) {
      this.form.coverUrl = data.url;
      this.form.coverCosKey = data.cosKey;
    },
    
    // // 获取音频时长函数
    // audioTime(time) {
    //   this.form.videoTime = time;
    // },
    // 视频函数
    videoFn(obj) {
      setTimeout(() => {
        this.keepLoading = false;
      }, 2000);
      this.form.oldFileName = obj.oldFileName;
      this.form.fileCosKey = obj.fileCosKey;
      this.form.fileUrl = obj.fileUrl;
      this.form.videoTime = parseInt(obj.videoTime);
      this.form.fileSize = obj.fileSize;
    },
    //音频函数
    audioFn(obj){
      this.form.appendix = []
      setTimeout(() => {
        this.keepLoading = false;
      }, 2000);
      let objs = {
        appendixName: obj.oldFileName,
        appendixCosKey: obj.fileCosKey,
        appendixUrl: obj.fileUrl,
        appendixTime: parseInt(obj.videoTime),
        fileSizeMp3: obj.fileSize
      }
      this.form.appendix.push(objs)
      console.log(this.form.appendix)
      this.form.appendixName = obj.oldFileName;
      this.form.appendixCosKey = obj.fileCosKey;
      this.form.appendixUrl = obj.fileUrl;
      this.form.appendixTime = parseInt(obj.videoTime);
      this.form.fileSizeMp3 = obj.fileSize;
    },
    // 改变视频文件名
    changeOldFileName(oldFileName) {
      this.form.oldFileName = oldFileName;
    },
    changeOldFileNameMp3(oldFileName) {
      this.form.appendixName = oldFileName;
    },
    // 富文本的函数
    editorFn(value) {
      this.form.description = value;
    },
    // 伴读文稿
    pairedDeseditorFn(value) {
      this.form.pairedDes = value;
    },
    //文稿
    pairedDeseditorFnNew(value){
      this.form.manuscript = value;
    },
    // 允许试学改变的回调
    studyFlagChange(e) {
      this.form.studyFlag = e.target.value;
    },
    // 上架设置改变的函数
    putawayFlagChange(e) {
      this.form.putawayFlag = e.target.value;
    },
    // 上架时间发生发生改变的回调
    putawayTimeChange(value, dateString) {
      this.form.putawayTime = value;
    },
    // 上架时间OK的回调
    putawayTimeOk(value) {
      this.form.putawayTime = value;
    },
    // 是否隐藏改变的回调
    hideFlagChange(e) {
      this.form.hideFlag = e.target.value;
    },
    // 保存的按钮
    keepClick() {
      this.keepLoading = true;
      setTimeout(() => {
        this.keepLoading = false;
      }, 3000);
      if (this.form.putawayFlag == 1) {
        if (this.form.putawayTime == "" || this.form.putawayTime == null) {
          message.error("请选择上架时间");
          return;
        }
      }
      this.form.putawayTime = format(
        new Date(this.form.putawayTime),
        "yyyy-MM-dd HH:mm:ss"
      );
      if (
        this.form.putawayTime == "1970-01-01 08:00:00" ||
        this.form.putawayTime == "NaN-aN-aN aN:aN:aN"
      ) {
        this.form.putawayTime = "";
      }
      
      if(!this.form.oldFileName){
        this.oldFileNameMp4Show = true
        return false
      }else{
        this.oldFileNameMp4Show = false
      }
      
      if(!this.form.oldFileName){
        this.oldFileNameMp4Show = true
        return false
      }else{
        this.oldFileNameMp4Show = false
      }
      
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log(this.form)
          this.form.manuscript = this.manuscriptList
          if (this.form.putawayFlag != 0) {
            if (this.$route.params.id != 0) {
              UpdLessonsApiv2(this.form).then(({code, msg}) => {
                if (code == 200) {
                  message.success("编辑成功");
                  location.href = `/coursManage/courseList/contentManage`;
                } else {
                  message.error(msg)
                }
              });
            } else {
              SaveLessonsApiv2(this.form).then(({code, msg}) => {
                if (code == 200) {
                  message.success("新增成功");
                  this.$router.go(-1);
                } else {
                  message.error(msg)
                }
              });
            }
          } else {
            message.error("请选择上架设置");
          }
        } else {
          console.log(valid)
          message.error("还有必填项未完成");
        }
      });
    },
    // 音频的回调
    // audioFn(data) {
    //   this.keepLoading = false;
    //   this.form.fileCosKey = data.cosKey;
    //   this.form.fileUrl = data.url;
    //   this.form.fileSize = data.maxSize;
    //   this.form.oldFileName = data.oldFileName;
    // },
    // audioLoadingFn(val) {
    //   this.keepLoading = val;
    // },
    // 获取课节列表函数
    GetCourseLessonsDesResultFn() {
      let id = this.$route.params.id;
      GetCourseLessonsDesResultApiV2({lessonNo: id}).then(({code, data}) => {
        if (code == 200) {
          this.form = data;
          this.manuscriptList = data.manuscript
          this.form.appendixName = data.appendix[0].appendixName
          // 根据映射关系获取对应的章节类型
          const cataloguType = this.getCatalogueTypeByLessonType(this.form.lessonsType);
          // 优先根据章节类型展示，无章节时根据课程类型展示
          this.changeUploadType(cataloguType);
        }
      });
    },
    // 封装获取老师列表的函数
    teacherSelectFn() {
      CourseTeacherSelectApi().then(({code, data}) => {
        if (code == 200) {
          this.teacherNoArr = data;
        }
      });
    },
    // 获取所属章节下拉列表
    ShowCatalogueByCourseNoFn(courseNo) {
      ShowCatalogueByCourseNoApi({courseNo}).then(({code, data}) => {
        if (code == 200) {
          this.catalogueNoArr = data;
        }
      });
    },
    // 获取所属章节下拉列表
    ClassClassification() {
      let obj = {
        pageNo: 20,
        pageSize: 0,
        categoryName: ''
      }
      categoryList().then(({code, data}) => {
        if (code == 200) {
          this.classification = data.list;
        }
      });
    },
    //字典查询函数
    DictionaryFn() {
      DictionaryApi().then(({code, data}) => {
        if (code == 200) {
          this.lessonsTypeArr = data.lessonsType;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ textarea.ant-input {
  resize: none;
}

/deep/ .description .ant-form-item-control {
  line-height: unset;
}

/deep/ .ant-upload-list-item-name {
  height: 42px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all linear 0.2s;
}

/deep/ .ant-upload-list-picture .ant-upload-list-item-name,
 .ant-upload-list-picture-card .ant-upload-list-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all linear 0.2s;
}

/deep/ .ant-upload-text {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  transition: all linear 0.2s;
}
.title{
  margin-bottom: 20px;
}
.wengao{
  padding: 20px;
  border: 1px solid #d4d4d4;
}
</style>
